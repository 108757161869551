import { createApp, provide, h } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import { createHead } from '@unhead/vue';
import { VueRecaptchaPlugin } from 'vue-recaptcha';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import * as Sentry from '@sentry/vue';
import { i18n } from '@/plugins/i18n';
import vuetify from '@/plugins/vuetify';
import mixpanelPlugin from '@/plugins/mixpanel';
import './assets/scss/main.scss';

import App from './App.vue';
import router from './router';
import { apolloClient } from './plugins/apollo';

const head = createHead();

const app = createApp({
    setup() {
        provide(DefaultApolloClient, apolloClient);
    },
    render: () => h(App),
});

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.MODE,
    tracesSampleRate: import.meta.env.MODE === 'production' ? 0.1 : 1.0,
    enabled: import.meta.env.MODE !== 'development',
    integrations: [
        new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
    ],
});

if ('serviceWorker' in navigator) {
    navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
            console.log(
                'Service Worker registered with scope:',
                registration.scope
            );
        })
        .catch((error) => {
            console.error('Service Worker registration failed:', error);
        });
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(head);
app.use(VueRecaptchaPlugin, {
    v3SiteKey: import.meta.env.VITE_RECAPTCHA_V3_SITE_KEY,
});
app.use(mixpanelPlugin, {
    token: import.meta.env.VITE_MIXPANEL_TOKEN,
    enabled: false,
    config: {
        debug: import.meta.env.MODE !== 'production',
    },
});
app.use(vuetify);
app.use(pinia);
app.use(router);
app.use(i18n);

app.mount('#app');
