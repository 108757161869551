import type { VuetifyOptions } from 'vuetify';

export default {
    global: {
        ripple: false,
    },
    VBreadcrumbsItem: {
        class: ['text-body-small'],
        disabled: true,
    },
    VBtn: {
        class: ['px-6 text-label-large text-none'],
        flat: true,
        rounded: true,
        style: '--v-btn-height: 3rem',
    },
    VBtnIcon: {
        flat: true,
        rounded: true,
        variant: 'text',
    },
    VBtnText: {
        class: ['px-4 text-primary text-label-large text-none'],
        rounded: true,
        style: '--v-btn-height: 3rem',
        variant: 'text',
    },
    VCheckboxBtn: {
        falseIcon: 'check_box_outline_blank',
        indeterminateIcon: 'indeterminate_check_box',
        trueIcon: 'check_box',
    },
    VCard: {
        flat: true,
        color: 'primary-100',
        class: ['px-5', 'py-5', 'rounded-md'],
        VCardTitle: {
            class: ['mb-4'],
        },
        VTextField: {
            variant: 'outlined',
        },
        VCard: {
            class: [],
            VCardTitle: {
                class: [],
            },
        },
    },
    VCardActions: {
        VBtn: {
            variant: 'flat',
        },
    },
    VCheckbox: {
        falseIcon: 'check_box_outline_blank',
        indeterminateIcon: 'indeterminate_check_box',
        trueIcon: 'check_box',
    },
    VChip: {
        rounded: true,
        variant: 'flat',
        class: ['bg-neutral-99'],
    },
    VColorPicker: {
        mode: 'hex',
        modes: ['hex'],
    },
    VContainer: {
        style: ['max-width: 1440px'],
        class: ['ml-0'],
    },
    VDataTable: {
        sortAscIcon: '$sort',
        sortDescIcon: '$reverse_sort',
        firstIcon: 'keyboard_double_arrow_left',
        lastIcon: 'keyboard_double_arrow_right',
        nextIcon: 'chevron_right',
        prevIcon: 'chevron_left',
        itemsPerPage: -1,
        VCheckboxBtn: {
            color: 'primary',
            VIcon: {
                size: 18,
            },
        },
    },
    VDateInput: {
        modeIcon: '$sort',
        nextIcon: 'chevron_right',
        prevIcon: 'chevron_left',
        clearIcon: '$close',
    },
    VDatePicker: {
        VBtn: {
            style: '',
        },
    },
    AppListTable: {
        AppPageTitle: {
            VBtn: {
                variant: 'outlined',
            },
        },
    },
    VDialog: {
        width: '552',
        transition: 'dialog-top-transition',
        VCard: {
            class: ['px-0', 'py-0'],
        },
        VToolbar: {
            color: 'surface-variant',
            height: 56,

            VToolbarTitle: {
                class: ['bg-surface-variant', 'text-title-medium', 'ml-6'],
            },
            VBtnIcon: {
                density: 'comfortable',
            },
        },
    },
    VMenu: {
        VList: {
            elevation: 0,
            rounded: 'md',
            class: ['py-0'],
            VListItem: {
                VIcon: {
                    class: ['opacity-100'],
                },
            },
        },
    },
    VOtpInput: {
        class: ['px-0', 'py-0'],
    },
    VProgressLinear: {
        color: 'secondary',
        height: 4,
        indeterminate: true,
    },
    VRadioGroup: {
        falseIcon: 'radio_button_unchecked',
        trueIcon: 'radio_button_checked',
        color: 'primary',
    },
    VSelect: {
        menuIcon: 'expand_more',
        variant: 'outlined',
        clearIcon: '$close',
    },
    VSelectRound: {
        class: ['v-select--round'],
        rounded: true,
        variant: 'outlined',
        menuIcon: 'expand_more',
        VOverlay: {
            offset: '8px',
            elevation: 0,
            class: ['elevation-0'],
        },
        VList: {
            flat: true,
            bgColor: 'primary-90',
            class: ['pa-0'],
        },
    },
    VAutocomplete: {
        menuIcon: 'expand_more',
        variant: 'outlined',
        clearIcon: '$close',
        clearable: true,
    },
    VTabs: {
        color: 'secondary',
        height: '4rem',
    },
    VTag: {
        class: ['v-tag'],
    },
    VTextarea: {
        clearIcon: '$close',
        color: 'primary',
        persistentClear: true,
        persistentHint: true,
        variant: 'outlined',
    },
    VTextField: {
        clearable: true,
        clearIcon: '$close',
        color: 'primary',
        persistentClear: true,
        persistentHint: true,
        variant: 'outlined',
    },
    VTooltip: {
        location: 'bottom',
        contentClass: ['text-body-small', 'bg-neutral-100', 'elevation-1'],
    },
    VPagination: {
        density: 'comfortable',
        prevIcon: 'chevron_left',
        nextIcon: 'chevron_right',
        totalVisible: 6,
    },
} as VuetifyOptions['defaults'];
