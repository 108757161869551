<script setup lang="ts">
import { RouteName } from '@/router';
import { useAuthStore } from '@/stores/auth';
import { computed, onMounted, onUnmounted, ref, watchEffect } from 'vue';
import {
    useRoute,
    useRouter,
    type RouteLocationNormalizedLoaded,
} from 'vue-router';
import { VCardText } from 'vuetify/components';
import { useI18n } from 'vue-i18n';
import AppModal from '@/components/AppModal.vue';
import { useIdle } from '@vueuse/core';

const { t } = useI18n({
    messages: {
        nl: {
            title: 'Inactiviteitswaarschuwing',
            text: 'Je bent 15 minuten inactief. Om veiligheidsredenen loggen we je automatisch uit.\n            Je wordt over {0} seconden uitgelogd.',
            hint: 'Verplaats de cursor of druk op een willekeurige toets om ingelogd te blijven.',
        },
        en: {
            title: 'Inactivity warning',
            text: "You've been inactive for 15 minutes. For security purposes we will be logging you out automatically.\n            You will be logged out in {0} seconds.",
            hint: 'Just move the cursor or hit any key to stay logged in.',
        },
    },
});

const { logout } = useAuthStore();
const router = useRouter();
const route = useRoute();
const currentRoute = ref<RouteLocationNormalizedLoaded>(route);

const idleTimeout = 1000 * 60 * 14; // 14 minutes
const logoutTimeout = 60; // 60 seconds
const { idle } = useIdle(idleTimeout);

const showModal = computed(
    () => isCountdownActive.value && route.name !== RouteName['account.login']
);

const remainingTime = ref(0);
const isCountdownActive = ref(false);
let countdownInterval: NodeJS.Timeout;

// Activity events to listen for
const activityEvents = ['click', 'mousemove', 'keydown', 'touchstart'];

const resetServiceWorkerTimer = () => {
    // Notify the service worker to reset the timer
    if (navigator.serviceWorker?.controller) {
        navigator.serviceWorker.controller.postMessage({ type: 'RESET_TIMER' });
    }
    resetTimer();
};

const startCountdown = (time: number, isServiceWorker: boolean = true) => {
    remainingTime.value = time;
    isCountdownActive.value = true;

    // Update the countdown every second
    countdownInterval = setInterval(() => {
        if (remainingTime.value > 0) {
            remainingTime.value -= 1;
            return;
        }
        resetTimer();
        // Log out user if timer was reset after the countdown was initiated from the app
        if (!isServiceWorker) {
            logoutUser();
        }
    }, 1000);
};

const resetTimer = () => {
    clearInterval(countdownInterval);
    isCountdownActive.value = false;
};

const handleServiceWorkerMessage = (event: MessageEvent<any>) => {
    if (event.data.type === 'SHOW_COUNTDOWN') {
        // Start countdown with the remaining time
        startCountdown(event.data.remainingTime);
    } else if (event.data.type === 'TIMER_RESET') {
        resetTimer();
    } else if (event.data.type === 'LOGOUT') {
        logoutUser();
    }
};

const logoutUser = () => {
    if (currentRoute.value.name === RouteName['account.login']) {
        return;
    }
    logout();
    router.push({ name: RouteName['account.login'] });
};

const handleUserActivity = () => {
    resetServiceWorkerTimer(); // Reset the inactivity timer on any user activity
};

const trackRouteChange = (to: RouteLocationNormalizedLoaded) => {
    currentRoute.value = to;
};

onMounted(() => {
    trackRouteChange(route);
    router.afterEach(trackRouteChange);
});

onMounted(() => {
    // Listen to user activity events
    activityEvents.forEach((event) => {
        window.addEventListener(event, handleUserActivity);
    });

    // Listen for messages from the service worker
    if (navigator.serviceWorker) {
        navigator.serviceWorker.addEventListener(
            'message',
            handleServiceWorkerMessage
        );
    }

    // Reset the timer on initial load
    resetServiceWorkerTimer();
});

onUnmounted(() => {
    activityEvents.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
    });

    if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener(
            'message',
            handleServiceWorkerMessage
        );
    }

    clearInterval(countdownInterval);
});

watchEffect(() => {
    if (currentRoute.value.name === RouteName['account.login']) {
        return;
    }

    if (idle.value) {
        startCountdown(logoutTimeout, false);
    } else {
        resetServiceWorkerTimer();
    }
});
</script>

<template>
    <AppModal v-model="showModal" :title="t('title')" width="700" height="240">
        <template #activator="{ props: activatorProps }">
            <slot name="activator" :props="activatorProps" />
        </template>
        <v-card-text class="pa-8">
            <p>
                {{ t('text', [remainingTime]) }}
            </p>
            <p>
                {{ t('hint') }}
            </p>
        </v-card-text>
    </AppModal>
</template>
